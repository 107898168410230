//
// BalanceText
// -----------------------------------------------------------------------------

// BalanceText
balanceText();



//
// AOS
// -----------------------------------------------------------------------------

// AOS
AOS.init({
  once: true
});



//
// Cover
// -----------------------------------------------------------------------------

// Set an interval to execute a function every 1.5 seconds
let interval = setInterval(function() {
  // Toggle class
  document.querySelector('.jsBtnCover').classList.toggle('is--active');
}, 1500)

// Set a timeout to execute a function after 3 seconds
setTimeout(function() {
  clearInterval(interval);
}, 3000)



//
// Event
// ----------------------------------------------------------------------------

// Set event date
const eventDate = new Date('2023-03-31');

// Update the count down every 1 second
setInterval(function () {
  // Get today's date and time
  const currentDate = new Date().getTime();

  // Find the distance between now and the event date
  const distance = eventDate - currentDate;

  // Calculate the days, hours, minutes, and seconds
  const days = Math.floor(distance / (1000 * 60 * 60 * 24));
  const hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
  const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
  const seconds = Math.floor((distance % (1000 * 60)) / 1000);

  // Update all timer elements with the calculated values
  const daysTimerElements = document.querySelectorAll(".jsEventTimeDays");
  daysTimerElements.forEach(function(element) {
    element.innerHTML = (days < 10 ? "0" : "") + days;
  });

  const hoursTimerElements = document.querySelectorAll(".jsEventTimeHours");
  hoursTimerElements.forEach(function(element) {
    element.innerHTML = (hours < 10 ? "0" : "") + hours;
  });

  const minutesTimerElements = document.querySelectorAll(".jsEventTimeMinutes");
  minutesTimerElements.forEach(function(element) {
    element.innerHTML = (minutes < 10 ? "0" : "") + minutes;
  });

  const secondsTimerElements = document.querySelectorAll(".jsEventTimeSeconds");
  secondsTimerElements.forEach(function(element) {
    element.innerHTML = (seconds < 10 ? "0" : "") + seconds;
  });
}, 1000);



//
// Chat
// -----------------------------------------------------------------------------

// Select the element
const chat = document.querySelector('.js-chat');

// Add an event listener for the scroll event on the window object
window.addEventListener('scroll', function() {
  // Select the elements and get its offsetHeight
  const top = document.querySelector('.top').offsetHeight;
  const cover = document.querySelector('.cover').offsetHeight;

  // Check if the value of scrollY is greater than the combined height of the elements
  if (window.scrollY > top + cover) {
    chat.classList.add('is-active');
  } else {
    chat.classList.remove('is-active');
  }
});



//
// Sliders
// -----------------------------------------------------------------------------

// Initiate Slider
$('.jsToolLg').slick({
  autoplay: true,
  speed: 1000,
  draggable: true,
  touchMove: true,
  infinite: true,
  focusOnSelect: true,
  pauseOnHover: true,
  swipeToSlide: true,
  accessibility: true,
  arrows: true
});

// Initiate Slider
$('.jsToolMd').slick({
  autoplay: false,
  speed: 1000,
  draggable: true,
  touchMove: true,
  infinite: true,
  focusOnSelect: true,
  pauseOnHover: true,
  swipeToSlide: true,
  accessibility: true,
  arrows: true,
  slidesToShow: 2,
  responsive: [
    {
      breakpoint: 880,
      settings: {
        slidesToShow: 1,
      }
    }
  ]
});

// Initiate Slider
$('.jsTestimonial').slick({
  variableWidth: true,
  autoplay: true,
  speed: 1000,
  draggable: true,
  touchMove: true,
  infinite: true,
  focusOnSelect: true,
  pauseOnHover: true,
  swipeToSlide: true,
  accessibility: true,
  arrows: true,
  responsive: [
    {
      breakpoint: 768,
      settings: {
        variableWidth: false,
        autoplay: false,
      }
    }
  ]
});


// Slick Control
$('.jsSlickControl').click( function() {
  const sliderClass = this.getAttribute('data-slider');
  if ($(this.children).html() == 'Pause'){
    $(`.${sliderClass}`).slick('slickPause')
    $(this.children[0]).html('Play');
    $(this).addClass('is-paused');
  } else {
    $(`.${sliderClass}`).slick('slickPlay')
    $(this.children[0]).html('Pause')
    $(this).removeClass('is-paused');
  }
});



//
// Menu
// -----------------------------------------------------------------------------

// Getting elements
const menuIcon = document.querySelector('.jsMenuIcon');
const nav = document.querySelector('.nav');
const header = document.querySelector('.header');

// Add an event listener for the click event
menuIcon.addEventListener('click', function() {
  menuIcon.classList.toggle('close');
  nav.classList.toggle('is-open');
  document.body.classList.toggle('menu-open');
});

// Outside click
document.addEventListener('click', function(event) {
  if (!header.contains(event.target)) {
    menuIcon.classList.remove('close');
    nav.classList.remove('is-open');
    document.body.classList.remove('menu-open');
  }
});



//
// Popup
// -----------------------------------------------------------------------------

const popupButtons = document.querySelectorAll('.jsPopupTrigger');

let isPopupOpen = false; // flag variable to track the state of the popup

for (let i = 0; i < popupButtons.length; i++) {
  const popupButton = popupButtons[i];
  const closeButton = document.querySelector('.jsPopupClose');
  const popupId = popupButton.getAttribute('data-target');
  const popup = document.querySelector(popupId);
  const body = document.body;

  // Show the popup when the button is clicked
  popupButton.addEventListener('click', () => {
    // Open the new popup
    popup.classList.add('is-open');
    body.classList.add('body--popup-open');
    popup.focus();

    // Set the flag to true
    isPopupOpen = true;
    console.log('Added is-open class');
  });

  // Hide the popup when the close button is clicked
  closeButton.addEventListener('click', () => {
    popup.classList.remove('is-open');
    body.classList.remove('body--popup-open');

    // Set the flag to false
    isPopupOpen = false;
    console.log('Removed is-open class');
  });

  document.addEventListener('click', function(event) {
    // Log the event type to the console
    // console.log(event.type);

    // Only proceed if the event is a click event
    if (event.type !== 'click') {
      return;
    }
  });

  // Hide the popup when the user presses the Escape key
  document.addEventListener('keydown', (event) => {
    if (event.key === 'Escape') {
      popup.classList.remove('is-open');
      body.classList.remove('.body--popup-open');
    }
  });
}
